@keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .fadeInUp {
    animation: fadeInUp 1s ease forwards;
  }
  
  .fadeIn {
    animation: fadeIn 1.5s ease forwards;
  }
  